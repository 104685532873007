import React, { useState, useEffect } from "react"
import Layout from "../components/Layout/layout";
import client from '../components/utils/client';

const Transporte = () => {
	const [allData, setAllData] = useState({});

	useEffect(() => {
		client()
			.get("content-group/14")
			.then(res => {
				let data = res.data || [];
				data.Contents.map(item => {
					if(item.Type === "image") {
						let obj = Object.assign(item);
						obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
						return obj;
					}
				})
				if(data.Contents) {
					setAllData(allData => ({
						...allData,
						header: {
							title: data.Contents.find(item => item.Key === "transporte-header-title").Value,
							image: data.Contents.find(item => item.Key === "transporte-header-image").Value,
						},
						main:{
							title: data.Contents.find(item => item.Key === "transporte-main-title").Value,
							paragraphs: data.Contents.filter(item => item.Key.includes("transporte-main-paragraph")).map(item => item.Value),
							images: data.Contents.filter(item => item.Key.includes("transporte-main-image")).map(item => item.Value),							
						},
					}))
				}
			})
			.catch(err => {
				console.log(err);
			})
	}, [])

	return (
		<Layout infoPage={{ page: "sobre", sub: "transporte" }}>
			<div className="transport">
				<div className="header" style={{background: allData.header ? `url(${allData.header.image})` : "#cfcfcf"}}>
					<h2>{allData.header ? allData.header.title : null}</h2>
				</div>
				<div className="container-70">
					{allData.main ? (
						<section className="body">
							<h2>{allData.main.title}</h2>
							{allData.main.paragraphs.map((item, index) => (
								<p key={index}>{item}</p>
							))}
							<div className="box__images">
								{allData.main.images.map((item, index) => (
									<img key={index} src={item} alt="transporte"/>
								))}
								{/* <img src={icon_2} alt="transporte"/>
								<img src={icon_3} alt="transporte"/> */}
							</div>
						</section>
					) : null}
				</div>
			</div>
		</Layout>
	)
};
export default Transporte